<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:content>
                <div class="grid p-fluid">
                    <div class="col-12 lg:col-10 lg:col-offset-1">
                        <Divider align="left" type="dashed">
                            <b>Includes</b>
                        </Divider>
                        <div class="field" >
                            <div v-for="(item, index) in included" :key="item.index" class="p-inputgroup">
                                <InputText
                                    id="included"
                                    v-model="included[index]"
                                    placeholder="Add Includes Here"
                                    :key="index"
                                    class="mb-2"
                                    style="float: left"
                                />
                                <Button
                                    label="Remove"
                                    @click="deleteIncluded(index)"
                                    v-if="included.length != 1"
                                    icon="pi pi-times"
                                    class="p-button p-button-danger p-button-sm mb-2"
                                    style="margin-left: 5px"
                                />
                            </div>
                            <Button
                                @click="addIncluded"
                                label="Add Includes"
                                style="width: 200px; float: right;"
                                class="mb-2"
                            />
                        </div>
                        <Divider class="p-mt-2" align="left" type="dashed">
                            <b>Excludes</b>
                        </Divider>
                        <div class="field" >
                            <div v-for="(exclude, index) in excluded" :key="index" class="p-inputgroup">
                                <InputText
                                    id="excluded"
                                    v-model="excluded[index]"
                                    placeholder="Add Excludes Here"
                                    :key="index"
                                    class="mb-2"
                                />
                                <Button
                                    label="Remove"
                                    @click="deleteExcluded(index)"
                                    v-if="excluded.length != 1"
                                    icon="pi pi-times"
                                    class="p-button p-button-danger p-button-sm mb-2"
                                    style="margin-left: 5px"
                                />
                            </div>
                            <Button
                                @click="addExcluded"
                                label="Add Excludes"
                                style="width: 200px; float: right;"
                                class="mb-2"
                            />
                        </div>
                        <Divider class="p-mt-2" align="left" type="dashed">
                            <b>Prerequisites</b>
                        </Divider>
                        <div class="field" >
                            <div v-for="(prerequisite, index) in prerequisites" :key="index" class="p-inputgroup">
                                <InputText
                                    id="prerequisites"
                                    v-model="prerequisites[index]"
                                    placeholder="Add Prerequisites Here"
                                    :key="index"
                                    class="mb-2"
                                />
                                <Button
                                    label="Remove"
                                    @click="deletePrerequisites(index)"
                                    v-if="prerequisites.length != 1"
                                    icon="pi pi-times"
                                    class="p-button p-button-danger p-button-sm mb-2"
                                    style="margin-left: 5px"
                                />
                            </div>
                            <Button
                                @click="addPrerequisites"
                                label="Add Prerequisites"
                                style="width: 200px; float: right;"
                                class="mb-2"
                            />
                        </div>
                        <Divider class="p-mt-2" align="left" type="dashed">
                            <b>Policies</b>
                        </Divider>
                        <div class="field">
                            <label for="tour_policy">Tour Policy</label>
                            <Editor
                                v-model="tour_policy"
                                editorStyle="height: 120px"
                                :autoResize="true"
                            >
                                <template v-slot:toolbar>
                                    <span class="ql-formats">
                                        <select class="ql-font">
                                            <option selected="selected"></option>
                                            <option value="serif"></option>
                                            <option value="monospace"></option>
                                        </select>
                                        <select class="ql-size">
                                            <option value="small"></option>
                                            <!-- Note a missing, thus falsy value, is used to reset to default -->
                                            <option selected></option>
                                            <option value="large"></option>
                                            <option value="huge"></option>
                                        </select>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-bold"></button>
                                        <button class="ql-italic"></button>
                                        <button class="ql-underline"></button>
                                        <button class="ql-strike"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <select class="ql-color">
                                                <option selected="selected"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option value="#ffffff"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
                                        </select>
                                        <select class="ql-background">
                                            <option value="#000000"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option selected="selected"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
                                        </select>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-script" value="sub"></button>
                                        <button class="ql-script" value="super"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-header" value="1"></button>
                                        <button class="ql-header" value="2"></button>
                                        <button class="ql-blockquote"></button>
                                        <button class="ql-code-block"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-list" value="ordered"></button>
                                        <button class="ql-list" value="bullet"></button>
                                        <button class="ql-indent" value="-1"></button>
                                        <button class="ql-indent" value="+1"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-direction ql-active" value="rtl" type="button"></button>
                                        <select class="ql-align">
                                            <option selected="selected"></option><option value="center"></option><option value="right"></option><option value="justify"></option>
                                        </select>
                                    </span>
                                </template>
                            </Editor>
                        </div>
                        <div class="field">
                            <label for="cancellation_policy">Cancellation Policy</label>
                            <Editor
                                v-model="cancellation_policy"
                                editorStyle="height: 120px"
                                :autoResize="true"
                            >
                                <template v-slot:toolbar>
                                    <span class="ql-formats">
                                        <select class="ql-font">
                                            <option selected="selected"></option>
                                            <option value="serif"></option>
                                            <option value="monospace"></option>
                                        </select>
                                        <select class="ql-size">
                                            <option value="small"></option>
                                            <!-- Note a missing, thus falsy value, is used to reset to default -->
                                            <option selected></option>
                                            <option value="large"></option>
                                            <option value="huge"></option>
                                        </select>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-bold"></button>
                                        <button class="ql-italic"></button>
                                        <button class="ql-underline"></button>
                                        <button class="ql-strike"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <select class="ql-color">
                                                <option selected="selected"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option value="#ffffff"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
                                        </select>
                                        <select class="ql-background">
                                            <option value="#000000"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option selected="selected"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
                                        </select>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-script" value="sub"></button>
                                        <button class="ql-script" value="super"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-header" value="1"></button>
                                        <button class="ql-header" value="2"></button>
                                        <button class="ql-blockquote"></button>
                                        <button class="ql-code-block"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-list" value="ordered"></button>
                                        <button class="ql-list" value="bullet"></button>
                                        <button class="ql-indent" value="-1"></button>
                                        <button class="ql-indent" value="+1"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-direction ql-active" value="rtl" type="button"></button>
                                        <select class="ql-align">
                                            <option selected="selected"></option><option value="center"></option><option value="right"></option><option value="justify"></option>
                                        </select>
                                    </span>
                                </template>
                            </Editor>
                        </div>
                        <div class="field">
                            <label for="children_policy">Children Policy</label>
                            <Editor
                                v-model="children_policy"
                                editorStyle="height: 120px"
                                :autoResize="true"
                            >
                                <template v-slot:toolbar>
                                    <span class="ql-formats">
                                        <select class="ql-font">
                                            <option selected="selected"></option>
                                            <option value="serif"></option>
                                            <option value="monospace"></option>
                                        </select>
                                        <select class="ql-size">
                                            <option value="small"></option>
                                            <!-- Note a missing, thus falsy value, is used to reset to default -->
                                            <option selected></option>
                                            <option value="large"></option>
                                            <option value="huge"></option>
                                        </select>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-bold"></button>
                                        <button class="ql-italic"></button>
                                        <button class="ql-underline"></button>
                                        <button class="ql-strike"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <select class="ql-color">
                                                <option selected="selected"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option value="#ffffff"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
                                        </select>
                                        <select class="ql-background">
                                            <option value="#000000"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option selected="selected"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option>
                                        </select>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-script" value="sub"></button>
                                        <button class="ql-script" value="super"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-header" value="1"></button>
                                        <button class="ql-header" value="2"></button>
                                        <button class="ql-blockquote"></button>
                                        <button class="ql-code-block"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-list" value="ordered"></button>
                                        <button class="ql-list" value="bullet"></button>
                                        <button class="ql-indent" value="-1"></button>
                                        <button class="ql-indent" value="+1"></button>
                                    </span>
                                    <span class="ql-formats">
                                        <button class="ql-direction ql-active" value="rtl" type="button"></button>
                                        <select class="ql-align">
                                            <option selected="selected"></option><option value="center"></option><option value="right"></option><option value="justify"></option>
                                        </select>
                                    </span>
                                </template>
                            </Editor>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="flex justify-content-between flex-wrap mb-4">
                    <div class="flex align-items-center justify-content-center">
                        <Button label="Back" @click="prevPage()" icon="pi pi-angle-left" style="width:200px"></Button>
                    </div>
                    <div class="flex align-items-center justify-content-center">
                        <Button label="Next" @click="nextPage(!$invalid)" icon="pi pi-angle-right" icon-pos="right" style="width:200px"></Button>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import {  ref, toRef } from 'vue';
import Axios from 'axios';
import { useToast } from 'primevue/usetoast';

export default {
    props: {
        slug: String
    },
    setup(props){
        const toast = useToast();
        const slug = toRef(props, 'slug');

        const submitted = ref(false);

        const tour_policy = ref();
        const cancellation_policy = ref();
        const children_policy = ref();
        const included = ref([]);
        const excluded = ref([]);
        const prerequisites = ref([]);

        const getSingleTour = () => {
            Axios
            .get("api/tour/view/"+slug.value)
            .then((res) => {
                if(res.data.response=="success") {
                    tour_policy.value = res.data.data.tour_policy;
                    cancellation_policy.value = res.data.data.cancellation_policy;
                    children_policy.value = res.data.data.children_policy;
                    included.value = res.data.data.included;
                    excluded.value = res.data.data.excluded;
                    prerequisites.value = res.data.data.prerequisites;
                }
                else {
                    console.log(res.data.message);
                }
            })
            .catch((err) => {
                showToast("error", "Error", "Could not fetch data. Try again!");
                console.log(err);
            });
        }
        getSingleTour();

        const addIncluded =  () => {
            included.value.push('');
        };
        const deleteIncluded =  (index) => {
            included.value.splice(index, 1);
        };
        const addExcluded =  () => {
            excluded.value.push('');
        };
        const deleteExcluded =  (index) => {
            excluded.value.splice(index, 1);
        };
        const addPrerequisites =  () => {
            prerequisites.value.push('');
        };
        const deletePrerequisites =  (index) => {
            prerequisites.value.splice(index, 1);
        };

        const showToast = (severity, summary, detail) => {
            toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
        }

        return{
            submitted,

            tour_policy,
            cancellation_policy,
            children_policy,
            included,
            excluded,
            prerequisites,
            addIncluded,
            deleteIncluded,
            addExcluded,
            deleteExcluded,
            addPrerequisites,
            deletePrerequisites,
        }
    },
    methods: {
        nextPage() {
            this.$emit('next-page', {formData: {
                tour_policy: this.tour_policy,
                cancellation_policy: this.cancellation_policy,
                children_policy: this.children_policy,
                included: this.included,
                excluded: this.excluded,
                prerequisites: this.prerequisites,
                }, pageIndex: 2});
        },
        prevPage() {
            this.$emit('prev-page', {pageIndex: 2});
        }
    }
}
</script>

<style>

</style>